<template>
  <div class="h-100">
    <v-row class="h-100 ma-0" no-gutters>
      <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown"
             class="h-100 d-flex justify-center align-center mr-10 background--yankee-blue">
        <v-img min-width="50px" contain src="@/assets/Logo_S2.png">
        </v-img>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1"></v-col>
      <v-col class="d-flex align-center pr-16">
        <v-card flat color="body-background">
          <v-row v-if="!$vuetify.breakpoint.smAndDown" class="ma-12"></v-row>
          <v-row>
            <v-card-title>
              <v-row class="align-baseline">
                <h1 class="grey--text text-h5 font-weight-bold">Welkom bij</h1>
                <v-img :width="$vuetify.breakpoint.smAndDown ? '160px' : '320px'" contain src="@/assets/Logo_blauw.png"
                       :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-n7'"/>
              </v-row>
            </v-card-title>
            <v-card-text class="pt-10">
              <v-form v-model="valid">
                <v-row justify="center" :class="{'w-75' : !$vuetify.breakpoint.smAndDown }">
                  <v-col class="pa-0">
                    <v-text-field autofocus outlined filled persistent-placeholder type="email" v-model.trim="email"
                                  label="Email"
                                  :rules="emailRules" required @keyup.native.enter="toPassword($event.target)"/>
                    <v-text-field id="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="showPassword ? 'text' : 'password'" v-model.trim="password"
                                  label="Wachtwoord" :rules="passwordRules" required
                                  @click:append="showPassword = !showPassword" class="mt-1"
                                  outlined filled persistent-placeholder @keyup.native.enter="login"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-block pt-0">
              <div class="mb-4">
                <v-btn type="button" color="secondary" @click="login">
                  Inloggen
                </v-btn>
              </div>
              <div class="text-body-2 mb-2">
                <router-link :to="{name: routeNames.FORGOT_PASSWORD}" class="pl-1 text-decoration-none">
                  Wachtwoord vergeten?
                </router-link>
              </div>
              <div class="text-body-2">
                <span>Nieuw bij Buildbase?</span>
                <router-link :to="{name: routeNames.PAYMENT}" class="pl-1 text-decoration-none">
                  Registreer.
                </router-link>
              </div>
            </v-card-actions>
          </v-row>
          <v-row class="ma-16"></v-row>
        </v-card>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="4"></v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RouteNames from "@/router/RouteNames";
import {getEmailRules, getPasswordRules} from "@/shared/utils/inputRulesUtils"
import {event} from 'vue-gtag'
import store from "@/store";

export default {
  name: "Login.vue",
  data() {
    return {
      valid: false,
      showPassword: false,
      email: null,
      password: null,
      emailRules: getEmailRules(),
      passwordRules: getPasswordRules(),
      routeNames: RouteNames
    }
  },
  methods: {
    login() {
      if (this.valid) {
        const user = {
          email: this.email.toLowerCase(),
          password: this.password.trim(),
        }
        this.$store.dispatch('authModule/login', user)
            .then(() => event('login', {
              method: 'Application',
              success: 'true',
              organizationId: store.getters["authModule/getOrganizationId"],
              userId: store.getters["authModule/getUserId"]
            })).catch(() => event('login', {
          method: 'Application',
          success: 'false'
        }))
      }
    },
    toPassword() {
      document.getElementById('password').focus()
    },
  }
}
</script>

<style lang="scss">
div.v-input.v-text-field--outlined.v-text-field--filled {
  div.v-input__slot {
    input {
      margin-top: 12px;
    }
  }
}
</style>
